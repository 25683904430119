export const testItems = {
  testItems: [
    { "test-item": "Glucose", unit: "mg/dL" },
    { "test-item": "Hemoglobin Alc", unit: "%" },
    { "test-item": "BUN", unit: "mg/dL" },
    { "test-item": "Creatinine", unit: "mg/dL" },
    { "test-item": "eGFR", unit: "mL/min/1.73" },
    { "test-item": "Sodium", unit: "mmol/L" },
    { "test-item": "Potassium", unit: "mmol/L" },
    { "test-item": "Chloride", unit: "mmol/L" },
    { "test-item": "Total Carbon Dioxide", unit: "mmol/L" },
    { "test-item": "Calcium", unit: "mg/dL" },
    { "test-item": "Total Protein", unit: "g/dL" },
    { "test-item": "Albumin", unit: "g/dL" },
    { "test-item": "Total Globulin", unit: "g/dL" },
    { "test-item": "A/G Ratio", unit: "" },
    { "test-item": "Total Bilirubin", unit: "mg/dL" },
    { "test-item": "Alkaline Phosphatase", unit: "IU/L" },
    { "test-item": "AST", unit: "IU/L" },
    { "test-item": "ALT", unit: "IU/L" },
    { "test-item": "Total Cholesterol", unit: "ng/mL" },
    { "test-item": "Triglycerides", unit: "mg/dL" },
    { "test-item": "HDL Cholesterol", unit: "mg/dL" },
    { "test-item": "VLDL Cholesterol Cal", unit: "mg/dL" },
    { "test-item": "LDL Chol Calc", unit: "mg/dL" },
    { "test-item": "TSH", unit: "uIU/mL" },
    { "test-item": "Vitamine D", unit: "ng/dL" },
  ],
};
