import { useState } from "react";
import "./App.css";
import { testItems } from "./util/testItems";
import { AiFillRobot } from "react-icons/ai";
import { RiDeleteBack2Fill } from "react-icons/ri";

function App() {
  const [advisor, setAdvisor] = useState("AI_Health_Advisor");
  const [recomendation, setRecomendation] = useState([]);
  const [user, setUser] = useState({});

  const handleAdvisor = (e) => {
    setAdvisor(e.target.value);
  };

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const clearRecommendation = () => {
    setRecomendation([]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("advisor", advisor);
    console.log("user", user);

    const response = await fetch(
      "https://robo-health-advisor-api.onrender.com",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          advisor,
          user,
        }),
      }
    );

    const data = await response.json();
    console.log(data);
    setRecomendation([
      ...recomendation,
      { advisor: `${data.advisor}`, message: `${data.message}` },
    ]);

    console.log(recomendation);
  };

  return (
    <div className="App">
      <aside className="sidemenu">
        <h2>Menu</h2>
        <nav>
          <ul>
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="/">Logout</a>
            </li>
          </ul>
        </nav>
      </aside>
      <main className="mainpage">
        <header>
          <h1>
            <span>
              <AiFillRobot />
              AI
            </span>
            Life Style Advisor
          </h1>
        </header>
        <section className="advisor">
          <h2>Who is your Advisor?</h2>

          <form onChange={handleAdvisor}>
            <div className="form-box">
              <div className="grid-container">
                <label htmlFor="advisor-select">Select Your Advisor:</label>
                <select onChange={(e) => setAdvisor(e.target.value)}>
                  <option value="AI_Health_Advisor">AI Health Advisor</option>
                  <option value="Nutrition_Coach">AI Nutrition Coach</option>
                  <option value="Personal_Trainer">AI Personal Trainer</option>
                </select>
              </div>
            </div>
          </form>
        </section>
        <section className="userinfo">
          <h2>Your Information</h2>
          <form onSubmit={handleSubmit}>
            <div className="form-box">
              <div className="grid-container">
                <label htmlFor="name">Nick Name:</label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-box">
              <div className="grid-container">
                <label htmlFor="age">Age:</label>
                <input
                  type="number"
                  name="age"
                  id="age"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-box">
              <div className="grid-container">
                <label htmlFor="gender">Gender:</label>
                <select name="gender" onChange={handleChange}>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </select>
              </div>
            </div>

            <div className="form-box">
              <div className="grid-container">
                <label htmlFor="smoker">Smoking:</label>
                <select name="smorking-frequency-level" onChange={handleChange}>
                  <option value="zero">No</option>
                  <option value="light-smoker">Yes: light smoker</option>
                  <option value="heavy-smoker">Yes: heavy smoker</option>
                </select>
              </div>
            </div>
            <div className="form-box">
              <div className="grid-container">
                <label htmlFor="drinker">Drinking:</label>
                <select name="drink-frequency-level" onChange={handleChange}>
                  <option value="zero">No</option>
                  <option value="light-drinker">Yes: light drinker</option>
                  <option value="heavy-drinker">Yes: heavy drinker</option>
                </select>
              </div>
            </div>

            <div className="form-box test-item-box">
              <div className="grid-container">
                {testItems.testItems.map((item) => (
                  <div key={item["test-item"]}>
                    <label htmlFor="scores">{item["test-item"]}: </label>
                    <input
                      type="float"
                      name={`${item["test-item"]}(${item["unit"]}) `}
                      placeholder={item["unit"]}
                      onChange={handleChange}
                    />
                  </div>
                ))}
              </div>
            </div>

            <button type="submit">Let's Ask</button>
          </form>
        </section>

        <section className="advise">
          <h2>Advice</h2>
          <p>
            Based on your information and selected advisor, we suggest the
            following:
          </p>
          <div>
            {recomendation.map((recomm, i) => (
              <div key={i}>
                <ul>
                  <li>
                    {recomm.advisor} - {recomm.message}
                  </li>
                </ul>
              </div>
            ))}
          </div>
          <div onClick={clearRecommendation}>
            <span>
              <RiDeleteBack2Fill />
            </span>
            Delete Recommendations
          </div>
          <div className="disclaimer">
            Warning -- Please note that I am not a licensed medical professional
            and my responses should not be taken as medical advice. If you have
            any concerns about your health, please consult a licensed medical
            professional for a proper diagnosis and treatment
          </div>
        </section>
      </main>
    </div>
  );
}

export default App;
